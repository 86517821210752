<template>
  <div>
    <banner></banner>
    <div class="content_area">
      <div class="content">
        <div class="list" v-for="(item, index) in contentList" :key="index">
          <div class="item">
            <div class="clearfix">
              <div class="img fl">
                <el-image :src="item.img" :fit="'scale-down'"></el-image>
              </div>
              <div class="fl marginL26">
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
            <div class="titleBrif">{{ item.titleBrif }}</div>
            <!--            <div class="more"><span class="point" @click="handleMore(item)">{{$t('knowMore')}} ></span></div>-->
          </div>
          <div v-if="item.line" class="line"></div>
        </div>
      </div>
      <div class="foot">
        <div class="title">{{ $t("exhibitorsGuide") }}</div>
        <div class="list">
          <div class="item" v-for="(item, index) in footList" :key="index">
            <div
              class="imgBox"
              :style="{ 'background-image': 'url(' + item.img + ')' }"
            >
              {{ item.title }}
            </div>
            <div class="linkList">
              <div
                class="linkItem"
                v-for="(link, ind) in item.links"
                :key="ind"
              >
                <el-button type="text" class="btn" @click="handleBtn(link)">{{
                  link.text
                }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "~exp/components/common/banner";
export default {
  name: "index",
  components: { banner },
  data() {
    return {
      contentList: [
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/audienceService/1.png",
          title: this.$t("cardManagement"),
          titleBrif: this.$t("audienceServiceTitleBrif1"),
          line: 1,
          path: "",
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/audienceService/2.png",
          title: this.$t("myRedPacket "),
          titleBrif: this.$t("audienceServiceTitleBrif2"),
          line: 1,
          path: "",
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/audienceService/3.png",
          title: this.$t("myAgenda"),
          titleBrif: this.$t("audienceServiceTitleBrif3"),
          line: 0,
          path: "",
        },
      ],
      footList: [
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/audienceService/4.png",
          links: [
            {
              text: this.$t("audienceServiceText1_1"),
              path: "",
            },
            {
              text: this.$t("applyFlow"),
              path: "",
            },
          ],
          title: this.$t("audienceServiceTitle1"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/audienceService/5.png",
          links: [
            {
              text: this.$t("audienceServiceText2_1"),
              path: "",
            },
            {
              text: this.$t("cardExchange"),
              path: "",
            },
            {
              text: this.$t("cardManagement"),
              path: "",
            },
          ],
          title: this.$t("audienceServiceTitle2"),
        },
        {
          img:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/audienceService/6.png",
          links: [
            {
              text: this.$t("audienceServiceText3_1"),
              path: "",
            },
            {
              text: this.$t("audienceServiceText3_2"),
              path: "",
            },
          ],
          title: this.$t("audienceServiceTitle3"),
        },
      ],
    };
  },
  methods: {
    handleMore(item) {
      alert("点击更多");
    },
    handleBtn(link) {
      // alert('点击按钮')
    },
  },
};
</script>

<style scoped lang="less">
.content {
  margin-top: 30px;
  display: flex;
  .list {
    display: flex;
    .item {
      width: 442px;
      height: 187px;
      padding: 37px 0 0 75px;
      .img {
        width: 50px;
        height: 50px;
      }
      .marginL26 {
        margin-left: 12px;
      }
      .title {
        color: #1f292e;
        font-size: 21px;
        font-weight: 800;
      }
      .titleBrif {
        padding-left: 60px;
        color: #1f292e;
        font-size: 16px;
        margin-top: -14px;
      }
      .more {
        margin: 20px 0 0 76px;
        color: #2292ff;
      }
    }
    .line {
      width: 1px;
      height: 187px;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 0) 0%,
        #d8d8d8 51%,
        rgba(216, 216, 216, 0) 100%
      );
    }
  }
}
.foot {
  padding-top: 30px;
  padding-bottom: 110px;
  .title {
    padding-bottom: 24px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    color: #333333;
  }
  .list {
    display: flex;
    justify-content: space-between;
    .item {
      width: 413px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.14);
      border: 1px solid #979797;
      .imgBox {
        width: 100%;
        height: 192px;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        padding: 0 20px;
      }
      .linkList {
        padding-bottom: 59px;
        text-align: center;
        .linkItem {
          margin-top: 16px;
          .btn {
            cursor: auto;
            font-size: 18px;
            color: #1f292e;
            &:hover {
              //color: #2292FF;
            }
          }
        }
      }
    }
  }
}
</style>